<template>

    <div class="row justify-content-center pt-3">
        <label for="yearSelect" class="col-form-label pr-2">
            <h5>Ano:</h5>
        </label>

        <select class="form-control" id="yearSelect" style="width: 85px; text-align-last: center"
                @change="onChange($event)">
            <option v-for="year in years" v-bind:key="year" :selected="year==currentYear">
                {{ year }}
            </option>
        </select>
    </div>

</template>

<script>
    export default {
      name: 'Holidays',
      data() {
        return {
          years: [],
          currentYear: this.$route.params.year
        };
      },
      methods: {
        initYears() {
          for (var year = 1980; year <= 2050; year++) {
            this.years.push(year);
          }
        },
           onChange(event) {
            this.$router.push('/ferias/'+event.target.value);
        }
      },
      created() {
        this.initYears();
      }
    };

</script>

<style scoped>
</style>
