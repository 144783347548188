<template>
    <div class="holidaysView">
        <Header/>
        <BackToTimelineButton/>
        <YearSelect/>
        <HolidaysTable/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import YearSelect from '@/components/holidays/YearSelect.vue'
import HolidaysTable from '@/components/holidays/HolidaysTable.vue'
import BackToTimelineButton from '@/components/holidays/BackToTimelineButton.vue'

export default {
  name: 'HolidaysView',
  components: {
    Header,
    BackToTimelineButton,
    YearSelect,
    HolidaysTable
  }
}
</script>
