<template>

    <div class="row justify-content-center pt-3 pb-3">
        <button
                type="submit"
                class="btn btn-info"
                @click="backToTimeline()">
            Voltar ao calculo de prazos
        </button>
    </div>

</template>

<script>
export default {
      methods: {
        backToTimeline() {
            this.$router.push('/prazos');
        }
    }
}
</script>

<style scoped>
</style>
