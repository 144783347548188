<template>

    <main class="container">
        <hr class="col-10">

        <div class="row justify-content-center">
            <div class="col-auto">
                <h3 class="font-weight-light">Feriados</h3>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col"></div>
            <div class="col">
                <table class="table">
                    <tbody>
                    <tr v-for="holiday in publicHolidays" v-bind:key="holiday">
                        <td style="min-width: 120px;">{{ holiday.date_formatted }}</td>
                        <td>{{ holiday.description }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col"></div>
        </div>

        <hr class="col-10">

        <div class="row justify-content-center">
            <div class="col-auto">
                <h3 class="font-weight-light">Férias</h3>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col"></div>
            <div class="col">
                <table class="table">
                    <tbody>
                    <tr v-for="holiday in justiceHolidays" v-bind:key="holiday">
                        <td style="min-width: 120px;">{{ holiday.date_formatted }}</td>
                        <td>{{ holiday.description }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col"></div>
        </div>

    </main>
</template>

<script>
export default {
  data () {
      return {
        publicHolidays: {},
        justiceHolidays: {}
      }
    },
    methods: {
      getHolidays() {
         var self = this
        const url = 'https://api.4lawyers.pt/holidays/'+this.$route.params.year
         this.axios.get(url, {
          dataType: 'json',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: 'no-cors'
        })
        .then(function (response) {
          self.publicHolidays = response.data.public_holidays
          self.justiceHolidays = response.data.justice_holidays
        })
        .catch(function (error) {
          console.log(error)
        })
      }
    },
    created() {
        this.getHolidays();
      },
}

</script>

<style scoped>
</style>
